import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { navigate } from 'gatsby-link'
import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import BtnLink from "../../components/addon/Link"
import API from '../../api'

const CareerListing = () => {

    const [listingContent, setListingContent] = useState('')
    const [bannerSec, setBannerSec] = useState('')
    const [listData, setlistData] = useState([])
    

    const listingContentFunc = () => {
        API.careerListing()
            .then(res => {
                if (res && res.content) {
                    setlistData([])
                    if(res.content.field_component_type){
                        res.content.field_component_type.map( item => {
                             if(item.field_banner_image){
                                 return setBannerSec(item)
                            }else if(item.field_component_items){
                                setlistData( current => [...current, item] )
                            }
                        })
                    }
                    setListingContent(res.content)
                    
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const goToDetails = (dataType,disc,designation, designationList) => {
        const detailPage = [{
            detailType : dataType,
            data : disc || '',
            designation : designation || '',
            designationList : designationList || ''
        }]
		//if(detailsType === 'exno') {
			//navigate(`/medical-report/details`, { state: { regType:'EX', hhNumber: exno } }) //exno
		//}else {
			navigate(`/careers/postings`, { state: { detailPage } }) //hhNumber
		//}
	}

    useEffect(() => {
        listingContentFunc()
    }, [])

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/services.css", "/assets/css/accessibility.css", "/assets/css/about-through-the-ages.css"],
                    }}
                />
                {listingContent && <section className="breadcrumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                    {
                                        listingContent.field_breadcrumb[0]?.field_breadcrumb_link.map( (item, i) => {
                                            return item?.uri ? <li key={i}><a href={item?.uri}>{item?.title}</a></li> : <li key={i}><span>{item?.title}</span></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>}

                <main className="innerpage pt-first-section pt-1">
                    <section class="inner_common_banner">
                        <picture class="banner_img">  
                            <img src="https://hinduja-assets.s3.ap-south-1.amazonaws.com/s3fs-public/2023-05/career-banner.jpg?VersionId=gze0lpPCzy5WAs6yqZatBiUFoLZXlnB0" width="1920" height="465" alt="career liscting banner" loading="lazy" typeof="foaf:Image" />
                        </picture>
                        <div class="banner_content text_left text_dark">
                            <div class="container">
                                <h1>Careers</h1>
                            </div>
                        </div>
                    </section>
                    <section class="section_bg about-ages py-4">
                        <div class="container pb-section">
                            <h2 class="pb-2">{bannerSec.field_title}</h2>
                            <p>{bannerSec.field_subtitle}</p>
                            <div dangerouslySetInnerHTML={{
                                __html: bannerSec.field_description
                              }}></div>
                        </div>
                    </section>
                    <section className="section_bg about-ages py-4">
                        <div className="container pb-section">
                            <div className="aboutContainer mt-4">
                                <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                                    {
                                        listData?.map( (item, i) => {
                                            return(
                                                <li role="presentation" className="nav-item">
                                                    <a className={`nav-link ${i === 0 ? 'active' : ''}`} data-toggle="tab" href={`#tabType${i}`} role="tab" aria-controls={item.field_title} aria-selected={i === 0 ? true : false}>{item.field_title}</a> 
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="tab-content mt-3">
                                {
                                    listData?.map( (item, i) => {
                                        return(
                                            <div className={`tab-pane ${i === 0 ? 'active' : ''}`} id={`tabType${i}`} role="tabpanel" aria-labelledby={item.field_title}>
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <ul className="bullet_list career_Bullet_List">
                                                                {
                                                                    item.field_component_items.map( (tmList, i) => {
                                                                        return(
                                                                            <li>
                                                                                <a href="javascript:;" onClick={ () => {goToDetails(item.field_title,tmList.field_description, tmList.field_title, item.field_component_items)}} title={item.field_title}>
                                                                                    <div className="careersList">
                                                                                        <h3 className="sel">{tmList.field_title}</h3>
                                                                                        <p>{tmList.field_subtitle}.....(click to read more)</p>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="btn_wrap mt-5">
                                <p>Looking to join our Parivar but can't find a job opening that matches your expertise? You can also apply here and if a relevant job requirement comes up, we will review your application and get back to you.</p>
                                <BtnLink classes="btn btn-primary-solid" data={{
                                    link: {
                                        uri: '/careers/postings',
                                        title: "Submit Your CV"
                                    }
                                }} />
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}

export default CareerListing